import { Component, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../shared/services';
import { getGameServer } from '../shared/utils';
import { AppLangService } from '../app-header';

@Component({
	selector: 'ra-agent-lobby',
	templateUrl: './agent-lobby.component.html',
	styleUrls: ['./agent-lobby.component.scss']
})
export class AgentLobbyComponent implements AfterViewInit {

	@ViewChild('launchLobbyScript', { static: false }) launchLobbyScriptContainer: ElementRef;

	private launchLobbyScript: any;

	constructor(
		private router: Router,
		private authService: AuthService,
		private appLangaugeService: AppLangService
	) { }

	ngAfterViewInit() {
		this.launchLobby();
	}

	@HostListener('window:message', ['$event'])
	windowMessageHandler(event: MessageEvent) {
		if (event.data.type === 'webpackOk') {
			return;
		}

		if (event.data !== undefined) {
			const data = event.data;
			switch (data.event) {
				case 'retailAgentBuyTicket':
					this.router.navigate(['buy-ticket', data.gameGroupCode]);
					break;
				case 'retailAgentQuickBuy':
					this.router.navigate(['buy-ticket', data.gameGroupCode], {
						queryParams: {
							isQuickbuy: true
						}
					});
					break;
				default:
					break;
			}
		}
	}

	private launchLobby() {
		this.launchLobbyScript = document.createElement('script');
		this.launchLobbyScript.type = 'text/javascript';
		this.launchLobbyScript.id = 'helio-launch-game';

		const apiKey = this.authService.authTokenData.payload.APIKey;
		const gameServer = getGameServer(this.authService.authTokenData.payload.ClusterID);
		const currencyCode = this.authService.authTokenData.payload.CurrencyCode;
		const languageCode = this.appLangaugeService.selectedLanguage;

		this.launchLobbyScript.text = `
			var lobbyConfig = {
				apiKey: "${apiKey}",
				gameServer: "${gameServer}",
				username: "",
				languageCode: "${languageCode}",
				playerSessionKey: "",
				currencyCode: "${currencyCode}",
				targetElement: "helio-lobby",
				width: "100%",
				height: "100%",
				mobileLobbyURL: "",
				retailAgentApp: true,
				gameGroupCategoryIDs: [1,5]
			};

			helioGaming.launchLobby(lobbyConfig);
		`;

		this.launchLobbyScriptContainer.nativeElement.appendChild(this.launchLobbyScript);
	}

}
